import { faPen, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useCallback, useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import Toggle from 'react-toggle'
import 'react-toggle/style.css'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddRack from '../../../components/modal/racks/ModalAddRack'
import ModalUpdateRack from '../../../components/modal/racks/ModalUpdateRack'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const titleStyle = {
  fontSize: '12px'
}

const MasterRack = () => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [racks, setRacks] = useState([])
  const [editId, setEditId] = useState(0)
  const [isSelected, setSelected] = useState(false)
  const [filterWarehouse, setFilterWarehouse] = useState({
    id: '',
    warehouseId: ''
  })
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const { per_page, current_page } = meta
  const navigate = useNavigate()
  useEffect(() => {
    fetchWarehouses()
  }, [])

  useEffect(() => {
    fetchRacks()
  }, [per_page, current_page, isSelected])

  const fetchWarehouses = useCallback(async () => {
    try {
      const res = await api.get('master/warehouses/all?is_parent=true')
      setWarehouses(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }, [])

  const fetchRacks = useCallback(async () => {
    setIsLoading(true)
    try {
      const params = {
        warehouse_id: filterWarehouse.id,
        per_page,
        page: current_page
      }
      const res = await api.get('master/racks', { params })
      const { metadata, data } = res.data
      setRacks(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }, [per_page, current_page, filterWarehouse.id])

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10)
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: data.selected + 1 }))
    window.scrollTo(0, 0)
  }

  const handleSelected = () => {
    setSelected((selected) => !selected)
    setMeta((prevMeta) => ({ ...prevMeta, current_page: 1 }))
    setDisabledAdd(filterWarehouse.id === '' ? true : false)
  }

  const handleEdit = (id) => {
    setShowModalUpdate(true)
    setEditId(id)
  }

  const handleToggle = async (id, status, rack) => {
    try {
      await api.patch(`master/racks/${id}`, {
        ...rack,
        is_active: !status
      })
      fetchRacks()
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Rack</h3>
      <Row>
        <Col xs={9} md={11} lg={11}>
          <Form.Label className="fw-normal text-muted" style={titleStyle}>
            <small>WAREHOUSE</small>
          </Form.Label>
          <Form.Select
            aria-label="Select Warehouse"
            name="warehouse"
            id="warehouse"
            onChange={(e) =>
              setFilterWarehouse({
                ...filterWarehouse,
                id: e.target.value,
                warehouseId: warehouses.find(
                  (warehouse) => warehouse.id === e.target.value
                ).warehouse_id
              })
            }
          >
            <option value="">All</option>
            {warehouses.map((warehouse) => (
              <option value={warehouse.id} key={warehouse.id}>
                {warehouse.warehouse_name}
              </option>
            ))}
          </Form.Select>
        </Col>
        <Col xs={3} md={1} lg={1}>
          <div
            className="d-flex h-100 align-items-center justify-content-end"
            style={{ marginTop: '.9rem' }}
          >
            <button
              className="btn btn-primary-orange"
              onClick={() => handleSelected()}
            >
              Select
            </button>
          </div>
        </Col>
      </Row>
      <div
        style={{
          borderBottom: '1px solid #F1F2F7',
          marginTop: '2.5rem'
        }}
      ></div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <h4
          className="text-muted mt-3 mt-md-0 mt-lg-0"
          style={{ fontSize: '14px' }}
        >
          RACK LIST
        </h4>
        <button
          className="btn btn-primary-orange"
          disabled={disabledAdd}
          onClick={() => setShowModalAdd(true)}
        >
          Add
        </button>
      </div>
      <div className="row">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2 align-middle">Name</th>
              <th className="p-2 align-middle">Rack Type</th>
              <th className="p-2 align-middle">Area</th>
              <th className="p-2 align-middle">Total Beam</th>
              <th className="p-2 align-middle">Total Level</th>
              <th className="p-2 align-middle">Status</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading && (
              <tr>
                <td colSpan={7}>
                  <Loader />
                </td>
              </tr>
            )}
            {!isLoading && racks.length === 0 && <RowEmpty colSpan={7} />}
            {!isLoading &&
              racks?.map((rack) => (
                <tr key={rack.id}>
                  <td className="p-2 align-middle">{rack.name}</td>
                  <td className="p-2 align-middle">
                    {firstLetterUpperCase(rack.type)}
                  </td>
                  <td className="p-2 align-middle">{rack?.area?.name}</td>
                  <td className="p-2 align-middle">{rack.total_beam}</td>
                  <td className="p-2 align-middle">{rack.total_level}</td>
                  <td className="p-2 align-middle">
                    <Toggle
                      className="actived"
                      defaultChecked={rack.is_active}
                      onClick={() =>
                        handleToggle(rack.id, rack.is_active, rack)
                      }
                      icons={false}
                    />
                  </td>
                  <td className="p-2 align-middle">
                    <TableAction>
                      {rack.type === 'rack' && (
                        <button
                          className="btn btn-secondary ml-2"
                          onClick={() =>
                            navigate(`/setting/racks/detail/${rack.id}`)
                          }
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ fontSize: '15px', rotate: '270deg' }}
                            className="fa-flip-horizontal"
                          />
                        </button>
                      )}
                      <button
                        className="btn btn-secondary ml-2"
                        onClick={() => handleEdit(rack.id)}
                      >
                        <FontAwesomeIcon
                          icon={faPen}
                          style={{ fontSize: '15px', rotate: '90deg' }}
                          className="fa-flip-horizontal"
                        />
                      </button>
                    </TableAction>
                  </td>
                </tr>
              ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={7} className="p-3">
                <TablePagination
                  meta={meta}
                  handlePageChange={handlePageChange}
                  handleOnChangeMeta={handleOnChangeMeta}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <ModalAddRack
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        warehouseId={filterWarehouse}
        refreshData={fetchRacks}
      />
      <ModalUpdateRack
        show={showModalUpdate}
        onHide={() => setShowModalUpdate(false)}
        id={editId}
        refreshData={fetchRacks}
        warehouseId={filterWarehouse}
      />
    </div>
  )
}

export default MasterRack
