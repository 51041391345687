import { faCaretDown, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import {
  Button,
  Col,
  Container,
  Form,
  OverlayTrigger,
  Popover,
  Row,
  Table
} from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { Link } from 'react-router-dom'
import {
  BadgeStatusCOA,
  BadgeStatusPrLists
} from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import ModalAddSelectStagingArea from '../../../components/modal/ReceiveML/ModalAddSelectStagingArea'
import api from '../../../config/AxiosInstance'
import { UserContext } from '../../../context/userContext'
import { convertDateWithTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
function Index() {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  const [username] = useContext(UserContext)
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [inboundId, setInboundId] = useState(null)
  const [modalStagingArea, setModalStagingArea] = useState(false)
  const [receiveWarehouses, setReceiveWarehouses] = useState([])
  const [filter, setFilter] = useState({
    ml_draft: '',
    receiver_warehouse: '',
    start_date: '',
    end_date: ''
  })
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })

  const handleChangeValueFilter = (e) => {
    const { name, value } = e.target
    setFilter((filter) => ({ ...filter, [name]: value }))
  }
  const handleResetFilter = () => {
    setFilter({
      ml_draft: '',
      receiver_warehouse: '',
      start_date: '',
      end_date: ''
    })
  }
  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handleApplyFilter = () => {
    if (moment(filter.start_date) > moment(filter.end_date)) {
      Common.showPopUpErrorMessage('Start date must be less than end date')
      return
    }
    fetchMLLists()
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }
  useEffect(() => {
    fetchMLLists()
  }, [meta.per_page, meta.current_page])

  useEffect(() => {
    fetchReceiverWarehouse()
  }, [])
  const fetchReceiverWarehouse = async () => {
    try {
      const response = await api.get('/master/warehouses/all?is_parent=true')
      const { data } = response
      if (data.success) {
        setReceiveWarehouses(data.data)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchMLLists = async () => {
    try {
      setLoading(true)
      const res = await api.get('/inbound/ml-draft/', {
        params: { ...filter, per_page: meta.per_page, page: meta.current_page }
      })
      const { data } = res
      if (data.success) {
        setData(data.data)
        setMeta(data.metadata)
      }
    } catch (error) {
      const errMsg =
        error?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleModalStagingArea = (inboundId) => {
    setModalStagingArea(true)
    setInboundId(inboundId)
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h3 style={headerTitleStyle}>ML Draft List</h3>
          </Col>
        </Row>
        {/* FILTER */}
        <Row className="mt-3">
          <Col md={3} className="mt-3">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary" htmlFor="ml_draft">
                <small>#ML DRAFT</small>
              </Form.Label>
              <Form.Control
                type="text"
                id="ml_draft"
                name="ml_draft"
                value={filter.ml_draft}
                onChange={(e) => handleChangeValueFilter(e)}
                placeholder="Enter ML Draft..."
              />
            </Form.Group>
          </Col>
          <Col md={3} className="mt-3">
            <Form.Group controlId="sjdelivend">
              <Form.Label className="fw-bold text-secondary">
                <small>RECEIVER WAREHOUSE</small>
              </Form.Label>
              <Form.Select
                aria-label="Select Receiver Warehouse"
                name="receiver_warehouse"
                id="receiver_warehouse"
                value={filter.receiver_warehouse}
                onChange={(e) => handleChangeValueFilter(e)}
              >
                <option value="">All</option>
                {receiveWarehouses?.map((warehouse) => (
                  <option key={warehouse.id} value={warehouse.id}>
                    {warehouse.warehouse_name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={5} className="mt-3">
            <Form.Group>
              <Form.Label className="fw-bold text-secondary">
                <small>CREATED</small>
              </Form.Label>
              <div className="d-flex flex-row gap-2">
                <InputDatePicker
                  value={filter?.start_date}
                  onChange={(date) =>
                    setFilter((filter) => ({
                      ...filter,
                      start_date: moment(date).format('YYYY-MM-DD')
                    }))
                  }
                />
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ fontSize: '13px' }}
                >
                  <span className="fw-bold">-</span>
                </div>
                <InputDatePicker
                  value={filter?.end_date}
                  onChange={(date) =>
                    setFilter((filter) => ({
                      ...filter,
                      end_date: moment(date).format('YYYY-MM-DD')
                    }))
                  }
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <div className="d-flex flex-row justify-content-end mt-5">
            <div className="d-flex gap-2">
              <Button variant="transparent" onClick={() => handleResetFilter()}>
                Reset Filter
              </Button>
              <Button
                variant="transparent"
                style={btnOrange}
                className="text-white"
                onClick={() => handleApplyFilter()}
              >
                Apply Filter
              </Button>
            </div>
          </div>
        </Row>
        <Row className="mt-3">
          <ReceiveTable
            data={data}
            meta={meta}
            handleOnChangeMeta={handleOnChangeMeta}
            handlePageChange={handlePageChange}
            loading={loading}
            handleModalStagingArea={handleModalStagingArea}
          />
        </Row>
        <ModalAddSelectStagingArea
          show={modalStagingArea}
          onHide={() => {
            setModalStagingArea(false)
          }}
          warehouseId={username?.username.roles[0]?.warehouse_id}
          inboundId={inboundId}
          refreshData={() => fetchMLLists()}
        />
      </Container>
    </>
  )
}
const ReceiveTable = ({
  data,
  meta,
  handleOnChangeMeta,
  handlePageChange,
  loading,
  handleModalStagingArea
}) => {
  const statusTextMapping = {
    unassigned: 'unassigned',
    waiting_rnr: 'waiting rnr',
    receiving_progress: 'receiving progress',
    rnr: 'receiving progress',
    waiting_om: 'waiting om',
    request_pnp: 'waiting om',
    waiting_pnp: 'waiting pnp',
    pnp: 'put away',
    put_away: 'put away',
    done: 'done',
    revised: 'waiting rnr'
  }
  const statusCoaMapping = {
    unchecked: 'unchecked',
    match: 'match',
    not_match: 'not match'
  }
  return (
    <Table
      hover
      responsive="sm"
      className="shadow-sm mb-5 bg-white rounded"
      size="sm"
      style={{ fontSize: '14px' }}
    >
      <thead className="table-secondary">
        <tr>
          <th className="p-2 align-middle">Created</th>
          <th className="p-2 align-middle">#ML Draft</th>
          <th className="p-2 align-middle">Target Warehouse</th>
          <th className="p-2 align-middle">Car</th>
          <th className="p-2 align-middle">Staging Area</th>
          <th className="p-2 align-middle text-end">Status COA</th>
          <th className="p-2 align-middle text-end">Status</th>
          <th className="p-2 align-middle text-end">Action</th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <tr>
            <td colSpan={8} className="text-center">
              <Loader />
            </td>
          </tr>
        )}
        {!loading && !data.length && (
          <tr>
            <td colSpan={8} className="text-center fw-bold">
              No Data
            </td>
          </tr>
        )}
        {!loading &&
          data.length > 0 &&
          data.map((item, i) => (
            <tr key={item.id}>
              <td className="p-2 align-middle">
                {convertDateWithTime(item?.createdAt)}
              </td>
              <td className="p-2 align-middle">{item?.ml_draft}</td>
              <td className="p-2 align-middle">{item?.target_warehouse}</td>
              <td className="p-2 align-middle">{item?.car ?? '-'}</td>
              <td className="p-2 text-end">
                {item?.inbounds?.stagings.length > 0 && (
                  <>
                    <OverlayTrigger
                      trigger={['hover', 'click']}
                      key={'bottom'}
                      placement={'bottom'}
                      overlay={
                        <Popover id={`popover-positioned-bottom`}>
                          <Popover.Body>
                            <p>
                              {' '}
                              {item?.inbounds?.stagings
                                .map((staging) => staging.name)
                                .join(', ')}
                            </p>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <div className="d-flex flex-row justify-content-end">
                        <p
                          style={{ cursor: 'pointer', maxWidth: '80%' }}
                          className="text-truncate"
                        >
                          {item?.inbounds?.stagings
                            .map((staging) => staging.name)
                            .join(', ')}
                        </p>
                      </div>
                    </OverlayTrigger>
                  </>
                )}
                {!item?.inbounds?.stagings.length && (
                  <>
                    <div
                      className="d-flex flex-row gap-2 justify-content-end cursor-pointer"
                      role="button"
                      onClick={() => handleModalStagingArea(item?.inbounds?.id)}
                    >
                      <span>Staging Area</span>
                      <FontAwesomeIcon
                        icon={faCaretDown}
                        className="fa-flip-horizontal ml-2 mt-1"
                      />
                    </div>
                  </>
                )}
              </td>
              <td className="p-2 text-end">
                <BadgeStatusCOA
                  text={statusCoaMapping[item?.coa_status] || 'unchecked'}
                />
              </td>
              <td className="p-2 text-end">
                <BadgeStatusPrLists
                  text={statusTextMapping[item?.inbounds?.status || 'done']}
                />
              </td>
              <td className="p-2 align-middle">
                <TableAction>
                  <Link to={`/inbound/ml/detail/${item.id}`}>
                    <Button variant="secondary" className="btn-sm">
                      <div
                        className="d-flex flex-column justify-content-center"
                        style={{ height: '20px' }}
                      >
                        <FontAwesomeIcon
                          icon={faSearch}
                          style={{ fontSize: '13px' }}
                        />
                      </div>
                    </Button>
                  </Link>
                </TableAction>
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={8} className="p-3">
            <TablePagination
              meta={meta}
              handleOnChangeMeta={handleOnChangeMeta}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default Index
