import { faQrcode, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PDFDownloadLink } from '@react-pdf/renderer'
import React, { useEffect, useState } from 'react'
import { Col, Form, InputGroup, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Loader from '../../../components/atoms/Loader'
import RowEmpty from '../../../components/atoms/RowEmpty'
import { BadgeStatusSj } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import Pdf from '../../../components/Download/Pdf'
import ModalDownloadQr from '../../../components/modal/pallete/ModalDownloadQr'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}
const typeWarehouses = [
  'All',
  'ground',
  'staging_area',
  'area_vas',
  'area_vas_pharma',
  'area_transit_vas',
  'area_transit_vas_pharma',
  'rack'
]
const MasterRackDetails = () => {
  const [showDownloadQr, setShowDownloadQr] = useState(false)
  const [showDownloadPdf, setShowDownloadPdf] = useState(false)
  const [loading, setLoading] = useState(false)
  const [slots, setSlots] = useState([])
  const [checkedSlots, setCheckedSlots] = useState([])
  const [editId, setEditId] = useState({
    src: '',
    paletName: '',
    id: ''
  })
  const [isSelected, setSelected] = useState(false)
  const [meta, setMeta] = useState(initialMeta)
  const [options, setOptions] = useState([])
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)
  const [inputWarehouse, setInputWarehouse] = useState('')
  const { per_page, current_page } = meta
  const [searchSlot, setSearchSlot] = useState({
    warehouseId: null,
    typeWarehouse: '',
    search: ''
  })
  const filterBy = () => true

  async function fetchSlots(searchSlot) {
    const { warehouseId, typeWarehouse, search } = searchSlot
    setLoading(true)
    try {
      setCheckedSlots([])
      setSlots([])
      const params = new URLSearchParams({
        per_page,
        page: current_page
      })
      if (warehouseId?.id) {
        params.append('warehouse_id', warehouseId.id)
        params.append('rack_type', typeWarehouse)
        params.append('q', search)
      }

      const res = await api.get(`master/slots?${params.toString()}`)
      const { metadata, data } = res.data
      setSlots(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  function handleDownloadQr(data) {
    setShowDownloadQr(true)
    setEditId({
      ...editId,
      src: data.src,
      paletName: data.paletName,
      id: data.id
    })
  }

  useEffect(() => {
    if (searchSlot.warehouseId) {
      fetchSlots(searchSlot)
    }
  }, [per_page, current_page, isSelected])

  const handleSearch = async (query) => {
    setLoadingWarehouse(true)
    setSearchSlot({
      ...searchSlot,
      warehouseId: null
    })
    setSelected(false)
    try {
      const params = new URLSearchParams({ page: 1, q: query })
      const res = await api.get(
        `master/warehouses/all?${params.toString()}&is_parent=true`
      )
      setOptions(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingWarehouse(false)
    }
  }

  const handleCheckInput = (e) => {
    const { value, checked } = e.target
    if (value === 'all') {
      // override to empty array first
      setCheckedSlots([])
      if (checked) {
        // get data from all id data palletes
        const allData = slots.reduce((arr, cur) => {
          arr.push({
            id: cur.id,
            qr: cur.qr,
            slot_name: cur.slot_name
          })
          return arr
        }, [])
        setCheckedSlots(allData)
      }
    } else {
      if (checked) {
        setCheckedSlots([...checkedSlots, JSON.parse(value)])
      } else {
        setCheckedSlots(
          checkedSlots.filter(
            (checkedSlot) => checkedSlot.id !== JSON.parse(value)?.id
          )
        )
      }
    }
  }

  const shardingItems = (propsDataValue) => {
    const data = JSON.parse(propsDataValue).reduce((acc, cur) => {
      acc.push({ id: cur.id, qr: cur.qr, name: cur.slot_name })
      return acc
    }, [])
    const shardedData = []

    for (let i = 0; i < data.length; i += 2) {
      shardedData.push(data.slice(i, i + 2))
    }
    return shardedData
  }

  const handleSelect = () => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: 1
    }))
    setSelected((prevSelected) => !prevSelected)
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Rack Detail</h3>
      <Row className="mt-3 gap-2 gap-md-0 gap-lg-0">
        <Col xs={12} md={5} lg={5}>
          <Form.Group>
            <Form.Label>Warehouse</Form.Label>
            <AsyncTypeahead
              id="basic-typeahead-single"
              labelKey="warehouse_name"
              loading={loadingWarehouse}
              filterBy={filterBy}
              onSearch={handleSearch}
              onChange={(selected) => {
                const selectedWarehouse =
                  selected.length > 0 ? selected[0] : null
                setInputWarehouse(
                  selectedWarehouse ? selectedWarehouse.warehouse_name : ''
                )
                setSearchSlot((prevSlot) => ({
                  ...prevSlot,
                  warehouseId: selectedWarehouse
                }))
              }}
              options={options}
              placeholder="Choose a warehouse..."
            />
          </Form.Group>
        </Col>
        <Col xs={12} md={5} lg={5}>
          <Form.Group>
            <Form.Label htmlFor="type-warehouses">Type</Form.Label>
            <Form.Select
              aria-label="type-warehouses"
              id="type-warehouses"
              value={searchSlot.typeWarehouse}
              onChange={(e) => {
                setSearchSlot((prevSlot) => ({
                  ...prevSlot,
                  typeWarehouse: e.target.value === 'All' ? '' : e.target.value
                }))
              }}
            >
              {typeWarehouses.map((type) => (
                <option key={type} value={type}>
                  {firstLetterUpperCase(type.replaceAll('_', ' '))}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        <Col xs={12} md={2} lg={2} className="mt-1">
          <div className="d-flex h-100 align-items-center mt-1">
            <button
              className="btn btn-primary-orange mt-3"
              onClick={() => handleSelect()}
              disabled={searchSlot.warehouseId === null ? true : false}
            >
              Select
            </button>
          </div>
        </Col>
      </Row>
      <div
        style={{
          borderBottom: '3px solid #F1F2F7',
          marginTop: '2.5rem'
        }}
      ></div>
      <Row className="mt-3 mb-3">
        <Col xs={12} md={8} lg={7} className="text-muted">
          <div className="d-flex flex-column justify-content-center mt-2">
            <div className="d-flex flex-column justify-content-center">
              <span className="fs-6 text-uppercase">Slot List</span>
            </div>
          </div>
        </Col>
        <Col
          xs={12}
          md={4}
          lg={5}
          className="d-flex flex-row gap-3 mt-3 mt-md-0 mt-lg-0 justify-content-start justify-content-md-end justify-content-lg-end"
        >
          <InputGroup style={{ width: '60%' }}>
            <Form.Control
              placeholder="Search Item Name..."
              aria-label="Search Item Name..."
              aria-describedby="basic-addon2"
              type="text"
              onInput={(e) => {
                setSearchSlot((prevSlot) => ({
                  ...prevSlot,
                  search: e.target.value
                }))
              }}
            />
            <InputGroup.Text
              id="basic-addon2"
              onClick={() => fetchSlots(searchSlot)}
              role="button"
              className="cursor-pointer"
            >
              <FontAwesomeIcon icon={faSearch} />
            </InputGroup.Text>
          </InputGroup>
          <div style={{ width: 'auto' }}>
            <PDFDownloadLink
              document={
                <Pdf data={shardingItems(JSON.stringify(checkedSlots))} />
              }
              fileName="print_qr_slot.pdf"
              onClick={() => {
                setShowDownloadPdf(true)
                setTimeout(() => {
                  setShowDownloadPdf(false)
                }, 1000)
              }}
            >
              {({ loading }) => (
                <button
                  className="btn btn-secondary fs-6 fs-md-5 fs-lg-5"
                  disabled={
                    checkedSlots.length === 0 || showDownloadPdf ? true : false
                  }
                >
                  {showDownloadPdf ? 'Download' : 'Print QR'}
                </button>
              )}
            </PDFDownloadLink>
          </div>
        </Col>
      </Row>
      <div className="row mt-2">
        <Table
          responsive="sm"
          hover
          className="shadow-sm mb-5 bg-white rounded"
          size="sm"
          style={{ fontSize: '14px' }}
        >
          <thead className="table-secondary">
            <tr>
              <th className="p-2">
                <input
                  className="staging-area form-check-input"
                  type="checkbox"
                  name="parent_slot"
                  value={'all'}
                  disabled={slots.length === 0 ? true : false}
                  checked={
                    checkedSlots.length === slots.length && slots.length !== 0
                      ? true
                      : false
                  }
                  onChange={(e) => handleCheckInput(e)}
                />
              </th>
              <th className="p-2 align-middle">Slot Name</th>
              <th className="p-2 align-middle">Palet ID</th>
              <th className="p-2 align-middle">Type</th>
              <th className="p-2 align-middle">Item Code</th>
              <th className="p-2 align-middle">Item Name</th>
              <th className="p-2 align-middle">Batch</th>
              <th className="p-2 align-middle">Received</th>
              <th className="p-2 align-middle">Exp Date</th>
              <th className="p-2 align-middle">Total Pack</th>
              <th className="p-2 align-middle">Total Qty</th>
              <th className="p-2 align-middle">Status</th>
              <th className="p-2 align-middle text-end">Action</th>
            </tr>
          </thead>
          <tbody>
            {loading && (
              <tr>
                <td colSpan={14}>
                  <Loader />
                </td>
              </tr>
            )}
            {!loading && slots.length === 0 ? (
              <RowEmpty colSpan={14} />
            ) : (
              slots?.map((slot, index) => (
                <TbodyRow
                  data={slot}
                  key={index}
                  handleCheckInput={handleCheckInput}
                  checkedSlots={checkedSlots}
                  onHandleDownloadQr={handleDownloadQr}
                />
              ))
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={14} className="p-3">
                <TablePagination
                  meta={meta}
                  handleOnChangeMeta={handleOnChangeMeta}
                  handlePageChange={handlePageChange}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
      </div>

      <ModalDownloadQr
        show={showDownloadQr}
        onHide={() => {
          setShowDownloadQr(false)
          setEditId({ ...editId, src: '', paletName: '', id: '' })
        }}
        paletData={editId}
      />
    </div>
  )
}

function TbodyRow({
  data,
  onHandleDownloadQr,
  handleCheckInput,
  checkedSlots
}) {
  const dataValue = {
    src: data.qr,
    paletName: data.palet_name,
    id: data.id
  }
  return (
    <tr key={data.id}>
      <td className="p-2">
        <input
          className="staging-area form-check-input"
          type="checkbox"
          name="parent_palette"
          value={JSON.stringify({
            id: data.id,
            slot_name: data.slot_name,
            qr: data.qr
          })}
          checked={
            checkedSlots.find((checkedSlot) => checkedSlot.id === data.id)
              ? true
              : false
          }
          onChange={(e) => handleCheckInput(e)}
        />
      </td>
      <td className="p-2 align-middle">{data.current_location}</td>
      <td className="p-2 align-middle">{data?.palet_name}</td>
      <td className="p-2 align-middle">
        {firstLetterUpperCase(data.type) || '-'}
      </td>
      <td className="p-2 align-middle">{data.item_code || '-'}</td>
      <td className="p-2 align-middle">{data.item_name || '-'}</td>
      <td className="p-2 align-middle">{data.batch || '-'}</td>
      <td className="p-2 align-middle">
        {data.exp_date ? convertDateWithoutTime(data.exp_date) : '-'}
      </td>
      <td className="p-2 align-middle">
        {data.exp_date ? convertDateWithoutTime(data.exp_date) : '-'}
      </td>
      <td className="p-2 align-middle">{data.total_pack || '-'}</td>
      <td className="p-2 align-middle">{data.total_qty || '-'}</td>
      <td className="p-2 align-middle">
        <BadgeStatusSj
          text={data.is_active ? 'active' : 'inactive'}
          bgColor={data.is_active ? 'success' : 'danger'}
        />
      </td>
      <td className="p-2 align-middle text-end">
        <TableAction>
          <button
            className="btn btn-secondary ml-2"
            onClick={() => onHandleDownloadQr(dataValue)}
          >
            <FontAwesomeIcon
              icon={faQrcode}
              style={{ fontSize: '15px', rotate: '90deg' }}
              className="fa-flip-horizontal"
            />
          </button>
        </TableAction>
      </td>
    </tr>
  )
}

export default MasterRackDetails
