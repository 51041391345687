import {
  Button,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Table
} from 'react-bootstrap'

import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Loader } from 'react-bootstrap-typeahead'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import ModalCreate from '../../../components/modal/ReceiveAtWarehousePlan/ModalCreate'
import Notification from '../../../components/Notifications/Notification'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
import { firstLetterUpperCase } from '../../../utils/TextFormatter'
const ReceiveAtWarehousePlan = () => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [notificationSuccess, setNotificationSuccess] = useState(false)
  const [filter, setFilter] = useState({
    src_warehouse_id: '',
    target_warehouse_id: '',
    start_date: '',
    end_date: '',
    tr: ''
  })
  const [createMl, setCreateMl] = useState({
    mlId: null,
    code: null,
    series_number: null
  })
  const [warehouses, setWarehouses] = useState([])
  const [targetWarehouses, setTargetWarehouses] = useState([])
  const [listData, setListData] = useState([])
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  useEffect(() => {
    fetchWarehouses()
    fetchTargetWarehouses()
    fetchListData()
  }, [])

  useEffect(() => {
    if (notificationSuccess) {
      const timer = setTimeout(() => {
        setNotificationSuccess(false)
      }, 10000)
      return () => clearTimeout(timer)
    }
  }, [notificationSuccess])
  const fetchWarehouses = async () => {
    try {
      const res = await api.get(`/master/warehouses/all`, {
        params: {
          is_parent: true
        }
      })
      const { data } = res
      if (data.success) {
        setWarehouses(data.data)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }
  const fetchTargetWarehouses = async () => {
    try {
      const res = await api.get(`/master/warehouses/all`, {
        params: {
          is_transit: true
        }
      })
      const { data } = res
      if (data.success) {
        setTargetWarehouses(data.data)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    }
  }

  const fetchListData = async () => {
    try {
      setIsLoading(true)
      const res = await api.get('/inbound/ml-plan/', {
        params: filter
      })
      const { data } = res
      if (data.success) {
        setListData(data.data)
        setMeta(data.metadata)
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setIsLoading(false)
    }
  }
  const handleChangeInputFilter = (e) => {
    const { name, value } = e.target
    setFilter({
      ...filter,
      [name]: value
    })
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  const handleCreateMlDraft = async () => {
    try {
      setDisableSubmit(true)
      const res = await api.post('inbound/ml-draft/create', {
        ml_id: createMl.mlId,
        series_number: createMl.series_number
      })
      const { data } = res
      if (data.success) {
        setShow(false)
        setNotificationSuccess(true)
        fetchListData()
      }
    } catch (error) {
      const err = error.response.data.errors.message || 'An error occurred'
      Common.showPopUpErrorMessage(err)
    } finally {
      setDisableSubmit(false)
    }
  }
  return (
    <>
      <Container>
        <Row>
          <Col md={4}>
            <h1 style={headerTitleStyle}>Receive At Warehouse Plan</h1>
          </Col>
          {notificationSuccess && (
            <Notification
              type="success"
              text="ML draft created!"
              handleClose={() => setNotificationSuccess(false)}
              link=""
            />
          )}
        </Row>
        <Row>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="src_warehouse_id"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Select
              name="src_warehouse_id"
              id="src_warehouse_id"
              value={filter.src_warehouse_id}
              onChange={(e) => handleChangeInputFilter(e)}
            >
              <option value="">All</option>
              {warehouses.map((warehouse) => (
                <option value={warehouse.id}>{warehouse.warehouse_name}</option>
              ))}
            </Form.Select>
          </Col>
          <Col md={3}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="target_warehouse_id"
            >
              <small>target warehouse</small>
            </Form.Label>
            <Form.Select
              name="target_warehouse_id"
              id="target_warehouse_id"
              value={filter.target_warehouse_id}
              onChange={(e) => handleChangeInputFilter(e)}
            >
              <option value="">All</option>
              {targetWarehouses.map((warehouse) => (
                <option value={warehouse.id}>{warehouse.warehouse_name}</option>
              ))}
            </Form.Select>
          </Col>

          <Col md={4} className="d-flex gap-2">
            <div className="w-50">
              <Form.Label
                className="fw-bold text-secondary text-uppercase"
                style={titleStyle}
                htmlFor="start_date"
              >
                <small>Delivery Date</small>
              </Form.Label>

              <InputDatePicker
                value={filter?.start_date}
                onChange={(date) => {
                  setFilter({
                    ...filter,
                    start_date: moment(date).format('YYYY-MM-DD')
                  })
                }}
              />
            </div>
            <h1 className="text-secondary d-flex flex-column h-100 justify-content-end">
              -
            </h1>
            <div className="w-50" style={{ marginTop: '2rem' }}>
              <Form.Label
                className="fw-bold text-secondary text-uppercase"
                htmlFor="end_date"
              >
                <small></small>
              </Form.Label>
              <InputDatePicker
                value={filter?.end_date}
                onChange={(date) => {
                  setFilter({
                    ...filter,
                    end_date: moment(date).format('YYYY-MM-DD')
                  })
                }}
              />
            </div>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={6}></Col>
          <Col md={6} className="text-end">
            <Button
              variant="transparent"
              className="btn text-muted fw-semibold me-2"
              onClick={() =>
                setFilter({
                  src_warehouse_id: '',
                  target_warehouse_id: '',
                  start_date: '',
                  end_date: '',
                  tr: ''
                })
              }
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => fetchListData()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={8}></Col>
          <Col md={4} className="text-end"></Col>
        </Row>
        <Row className="mt-3">
          <Col md={9}></Col>
          <Col md={3}>
            <InputGroup className="mb-3">
              <Form.Control
                aria-label="Example text with button addon"
                aria-describedby="basic-addon1"
                placeholder="Search TR"
                className="border-end-0"
                name="tr"
                value={filter.tr}
                onChange={(e) => handleChangeInputFilter(e)}
              />
              <Button
                className="border-start-0 border-secondary-subtle"
                id="button-addon1"
                variant="transparent"
                disabled
              >
                <FontAwesomeIcon icon={faMagnifyingGlass} />
              </Button>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-2 align-middle">Delivery Date</th>
                  <th className="p-2 align-middle">#TR</th>
                  <th className="p-2 align-middle">#MR</th>
                  <th className="p-2 align-middle">Source Warehouse</th>
                  <th className="p-2 align-middle">Target Warehouse</th>
                  <th className="p-2 align-middle">Delivery Type</th>
                  <th className="p-2 align-middle">Driver</th>
                  <th className="p-2 align-middle">Car</th>
                  <th className="p-2 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={9} className="text-center">
                      <Loader />
                    </td>
                  </tr>
                )}
                {!isLoading && listData.length === 0 && (
                  <tr>
                    <td colSpan={9} className="text-center fw-bold">
                      No data
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  listData.length > 0 &&
                  listData?.map((data) => (
                    <tr key={data?.id}>
                      <td className="p-2 align-middle">
                        {convertDateWithoutTime(data?.delivery_date)}
                      </td>
                      <td className="p-2 align-middle">{data?.tr}</td>
                      <td className="p-2 align-middle">{data?.mr}</td>
                      <td className="p-2 align-middle">
                        {data?.src_warehouse}
                      </td>
                      <td className="p-2 align-middle">
                        {data?.target_warehouse}
                      </td>
                      <td className="p-2 align-middle">
                        {firstLetterUpperCase(data?.delivery_type)}
                      </td>
                      <td className="p-2 align-middle">
                        {firstLetterUpperCase(data?.driver)}
                      </td>
                      <td className="p-2 align-middle">{data?.plate_no}</td>
                      <td className="p-2 align-middle">
                        <TableAction>
                          <button
                            className="btn btn-primary-blue ms-2 btn-sm"
                            onClick={() => {
                              setCreateMl((prevCreateMl) => ({
                                ...prevCreateMl,
                                mlId: data?.id,
                                code: data?.tr
                              }))
                              setShow(true)
                            }}
                          >
                            + ML DRAFT
                          </button>
                        </TableAction>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-2" colSpan={10}>
                    <TablePagination
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
        <ModalCreate
          show={show}
          onHide={() => setShow(false)}
          createMl={createMl}
          setCreateMl={setCreateMl}
          handleCreateMlDraft={handleCreateMlDraft}
          disableSubmit={disableSubmit}
        />
      </Container>
    </>
  )
}

export default ReceiveAtWarehousePlan
