import { faCircleNotch, faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import Select from 'react-select'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import api from '../../../config/AxiosInstance'
import * as Common from '../../../utils/Common'
import { formatThousandSeparator } from '../../../utils/TextFormatter'
const CreateOutboundPlan = () => {
  const [warehouses, setWarehouses] = useState([])
  const { state } = useLocation()
  const navigate = useNavigate()
  // Styles
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const labelFormStyle = {
    fontSize: '12px'
  }

  // State
  const [dataSj, setDataSj] = useState([])
  const [selectSj, setSelectSj] = useState(state?.selectedSj ?? [])
  const [loadingListSj, setLoadingListSj] = useState(false)
  const [searchDataSj, setSearchDataSj] = useState(
    state?.filterData ?? {
      src: '',
      delivery_date: '',
      srcLabel: ''
    }
  )
  const [buttonSearch, setButtonSearch] = useState(false)
  useEffect(() => {
    getWarehouses()
  }, [])
  useEffect(() => {
    if (state) {
      getSjDraftLists()
    }
  }, [state])
  // Event Handlers
  const handleSelectSj = (e) => {
    const { value, checked } = e.target
    setDataSj((prevData) =>
      prevData.map((sj) =>
        sj.draft === value ? { ...sj, selected: checked } : sj
      )
    )
    setSelectSj((prevSelect) =>
      checked
        ? [...prevSelect, dataSj.filter((sj) => sj.draft === value)[0]]
        : prevSelect.filter((sj) => sj !== value)
    )
  }

  const handleDeleteSj = (id) => {
    setDataSj((prevData) =>
      prevData.map((sj) => (sj.draft === id ? { ...sj, selected: false } : sj))
    )
    setSelectSj((prevSelect) => prevSelect.filter((sj) => sj.draft !== id))
  }

  const getWarehouses = async () => {
    try {
      const res = await api.get('/master/warehouses/all?is_parent=true')
      if (res.status === 200) {
        const options = res.data.data.reduce((acc, data) => {
          acc.push({ value: data.warehouse_id, label: data.warehouse_name })
          return acc
        }, [])
        setWarehouses(options)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }

  const getSjDraftLists = async () => {
    try {
      setLoadingListSj(true)
      const res = await api.post('/outbound/sjdraft/search', searchDataSj)
      if (res.status === 200) {
        const values = res.data.data
          .filter((itemSj) => itemSj.is_available)
          .reduce((acc, sj) => {
            // check condition
            const filterSjSelected = selectSj.filter(
              (sjValue) => sjValue.sj === sj.sj
            )
            acc.push({
              ...sj,
              selected: filterSjSelected.length > 0 ? true : false
            })
            return acc
          }, [])
        setDataSj(values)
        // override button search to value true
        setButtonSearch(true)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingListSj(false)
    }
  }

  const handleSearchDataSj = (name, value) => {
    // set button search to value false
    setButtonSearch(false)
    if (state) {
      navigate('', { state: null })
    }
    setSearchDataSj((prevSearchDataDj) => ({
      ...prevSearchDataDj,
      [name]: value === 'self-pickup' ? 'pick up' : value
    }))
  }

  const customStyles = {
    control: (base) => ({
      ...base,
      height: 33,
      minHeight: 33
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '30px',
      padding: '0 6px'
    }),
    input: (provided, state) => ({
      ...provided,
      margin: '0px'
    })
  }
  const propSearchSj = () => {
    if (searchDataSj.src === '' || searchDataSj.delivery_date === '') {
      return { variant: 'secondary', disabled: true, className: 'ml-3' }
    }
    return {
      variant: 'transparent',
      className: 'btn-primary-orange ml-3',
      onClick: () => handleSearchSjDraft()
    }
  }

  const handleSearchSjDraft = () => {
    // change selected sj to empty array
    setButtonSearch(true)
    setSelectSj([])
    getSjDraftLists()
  }

  const calculateTotalQty = (type) => {
    let qtyMap = []
    if (selectSj.length > 0) {
      selectSj.map((sjValue) => {
        sjValue.pickup_command.forEach((command) => {
          if (type === 'qty') {
            const { qty, uom } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty, uom })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom
                    ? { ...value, qty: (value.qty += qty) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty, uom })
              }
            }
          }
          if (type === 'pack') {
            const { total_pack, uom_pack } = command
            if (qtyMap.length === 0) {
              qtyMap.push({ qty: total_pack, uom: uom_pack })
            } else {
              const filterQty = qtyMap.filter(
                (dataValue) => dataValue.uom === uom_pack
              )
              if (filterQty.length > 0) {
                const newValues = qtyMap.map((value) =>
                  value.uom === uom_pack
                    ? { ...value, qty: (value.qty += total_pack) }
                    : value
                )
                qtyMap = newValues
              } else {
                qtyMap.push({ qty: total_pack, uom: uom_pack })
              }
            }
          }
        })
      })
      const totalQty = qtyMap
        .map((data) => `${data.qty} ${data.uom}`)
        .join(' + ')
      return totalQty
    }
    return 0
  }

  const calculateTotalVolume = () => {
    if (selectSj.length > 0) {
      let tmpVolume = []
      selectSj.map((sjValue) => {
        sjValue.pickup_command.map((pickupCommand) => {
          tmpVolume.push(pickupCommand.qty * pickupCommand.volume_item)
        })
      })

      if (tmpVolume.length > 0) {
        const totalVolume = tmpVolume.reduce((acc, volume) => {
          return (acc += volume ?? 0)
        }, 0)
        return totalVolume
      }
    }
    return 0
  }

  return (
    <div style={{ minHeight: '100vh' }}>
      <Container>
        <Row>
          <Col>
            <h4 style={headerTitleStyle}>Create Outbound Plan</h4>
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb" style={{ fontSize: '12px' }}>
                <li className="breadcrumb-item sj-item">
                  <Link to="/outbound/plan" className="text-decoration-none">
                    <span className="text-black fw-bold">OUTBOUND PLAN</span>
                  </Link>
                </li>
                <li
                  className="breadcrumb-item sj-item active text-uppercase"
                  aria-current="page"
                >
                  Create
                </li>
              </ol>
            </nav>
          </Col>
        </Row>
        <Row>
          <Col>
            <p className="text-muted">Please fill all the fields</p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={4} lg={4}>
            <Form.Group className="mb-3 text-muted" controlId="sourcewarehouse">
              <Form.Label style={labelFormStyle}>SOURCE WAREHOUSE</Form.Label>
              <Select
                name="src"
                options={warehouses}
                onChange={(e) => {
                  handleSearchDataSj('src', e?.value ?? '')
                  setSearchDataSj((prevSearchDataSj) => ({
                    ...prevSearchDataSj,
                    srcLabel: e?.label ?? ''
                  }))
                }}
                isClearable
                isSearchable
                styles={customStyles}
                value={warehouses.find(
                  (warehouse) => warehouse.value === searchDataSj.src
                )}
              />
            </Form.Group>
          </Col>
          <Col xs={12} md={3} lg={4}>
            <Form.Group className="mb-3 text-muted" controlId="deliverydate">
              <Form.Label style={labelFormStyle}>DELIVERY DATE</Form.Label>
              <div className="d-flex flex-row gap-3">
                <div className="w-50">
                  <InputDatePicker
                    value={searchDataSj.delivery_date}
                    onChange={(date) =>
                      setSearchDataSj((prev) => ({
                        ...prev,
                        delivery_date: moment(date).format('YYYY-MM-DD')
                      }))
                    }
                  />
                </div>
                <Button {...propSearchSj()} style={{ width: '30%' }}>
                  Search
                </Button>
              </div>
            </Form.Group>
          </Col>
        </Row>
      </Container>
      {state && !buttonSearch && (
        <div className="d-flex flex-row justify-content-center p-3">
          <div className="d-flex flex-row gap-2">
            <FontAwesomeIcon icon={faCircleNotch} spin className="mt-1" />
            <span className="fs-6">Loading...</span>
          </div>
        </div>
      )}
      {searchDataSj.src !== '' &&
        searchDataSj.delivery_date !== '' &&
        buttonSearch && (
          <>
            <div
              style={{ borderTop: '3px solid #F1F2F7' }}
              className="mt-3 mb-3"
            ></div>
            <Container className="mt-4 mb-5">
              <Row className="d-flex flex-row gap-3 gap-md-0 gap-lg-0">
                <Col xs={12} md={6} lg={6}>
                  {loadingListSj && (
                    <div className="text-center">
                      <Loader />
                    </div>
                  )}
                  {!loadingListSj &&
                    dataSj?.filter((sjFilter) => !sjFilter.selected).length ===
                      0 && <p className="text-center fw-bold">Data empty</p>}
                  {!loadingListSj &&
                    dataSj
                      ?.filter((sjFilter) => !sjFilter.selected)
                      .map((sj, index) => (
                        <div
                          key={sj.draft}
                          className={`${
                            index > 0 ? 'mt-2' : ''
                          } form-check d-flex flex-row gap-3`}
                        >
                          <input
                            className="staging-area form-check-input"
                            type="checkbox"
                            name="sj"
                            value={sj.draft}
                            id={`flexCheckDefault${sj.draft}`}
                            onChange={(e) => handleSelectSj(e)}
                          />
                          <label
                            className="form-check-label ml-3 mt-1"
                            htmlFor={`flexCheckDefault${sj.draft}`}
                          >
                            <p>{sj.draft}</p>
                            <p>{sj.customer}</p>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: sj.delivery_address
                              }}
                            ></div>
                            <ol style={{ marginLeft: '-1rem' }}>
                              {sj.pickup_command?.map((item, idx) => (
                                <li key={item.id}>
                                  {item.qty} {item.qty_uom} &#x2022;{' '}
                                  {item.item_name}
                                </li>
                              ))}
                            </ol>
                          </label>
                        </div>
                      ))}
                </Col>
                <Col xs={12} md={6} lg={6}>
                  <h6
                    className="text-uppercase fw-bold mb-3"
                    style={{ color: '#828282' }}
                  >
                    Selected Ticket
                  </h6>
                  <div className="row">
                    <div className="col-12">
                      {selectSj.length === 0 && (
                        <p
                          className="text-capitalize"
                          style={{ fontSize: '11px', color: '#828282' }}
                        >
                          Select pick up tickets from the left (from the
                          furthest to nearest) to deliver through this outbound
                          ticket. For better results, make sure the tickets are
                          in the same area.
                        </p>
                      )}
                      {selectSj.length > 0 && (
                        <>
                          {selectSj.map((sj, index) => (
                            <div
                              key={sj.id}
                              className={`${
                                index > 0 ? 'mt-2' : ''
                              } form-check d-flex flex-row gap-3`}
                              style={{ marginLeft: '-1.5rem' }}
                            >
                              <div className="d-flex flex-row gap-3">
                                <div
                                  onClick={() => handleDeleteSj(sj.draft)}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <FontAwesomeIcon
                                    icon={faTrash}
                                    className="fa-flip-horizontal"
                                    style={{
                                      backgroundColor: '#C12727',
                                      color: 'white',
                                      padding: '.5rem',
                                      borderRadius: '3px'
                                    }}
                                  />
                                </div>
                                <label
                                  className="form-check-label ml-3 mt-1"
                                  htmlFor={`flexCheckDefault${sj.draft}`}
                                >
                                  <p className="fw-bold">
                                    <span>{index + 1}.</span> {sj.draft}
                                  </p>
                                  <p>{sj.customer}</p>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: sj.delivery_address
                                    }}
                                  ></div>
                                  <ol style={{ marginLeft: '-1rem' }}>
                                    {sj.pickup_command.map((item, idx) => (
                                      <li key={item.id}>
                                        {item.qty} {item.qty_uom} &#x2022;{' '}
                                        {item.item_name}
                                      </li>
                                    ))}
                                  </ol>
                                </label>
                              </div>
                            </div>
                          ))}
                          <div style={{ borderTop: '3px solid #F1F2F7' }}></div>
                          <div className="mt-3">
                            <Table borderless>
                              <tbody>
                                <tr>
                                  <td className="fw-bold" width={'10%'}>
                                    Qty
                                  </td>
                                  <td className="fw-bold" width={'5%'}>
                                    :
                                  </td>
                                  <td>
                                    <span
                                      style={{ color: '#F7931E' }}
                                      className="fw-bold"
                                    >
                                      {calculateTotalQty('qty')}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold" width={'10%'}>
                                    Pack
                                  </td>
                                  <td className="fw-bold" width={'5%'}>
                                    :
                                  </td>
                                  <td>
                                    <span
                                      style={{ color: '#F7931E' }}
                                      className="fw-bold"
                                    >
                                      {calculateTotalQty('pack')}
                                    </span>
                                  </td>
                                </tr>
                                <tr>
                                  <td className="fw-bold" width={'10%'}>
                                    Vol
                                  </td>
                                  <td className="fw-bold" width={'5%'}>
                                    :
                                  </td>
                                  <td>
                                    <span
                                      style={{ color: '#F7931E' }}
                                      className="fw-bold"
                                    >
                                      {formatThousandSeparator(
                                        calculateTotalVolume()
                                      )}{' '}
                                      cm3
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>

                          <Link
                            to="/outbound/plan/create/detail"
                            state={{
                              selectedSj: selectSj,
                              filterData: {
                                ...searchDataSj
                              }
                            }}
                          >
                            <Button
                              variant="transparent"
                              className="btn-primary-orange w-100"
                            >
                              Continue
                            </Button>
                          </Link>
                        </>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
    </div>
  )
}

export default CreateOutboundPlan
