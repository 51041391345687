import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useEffect, useState } from 'react'
import { Button, Col, Container, Form, Row, Table } from 'react-bootstrap'
import { Loader } from 'react-bootstrap-typeahead'
import moment from 'moment'
import 'react-calendar/dist/Calendar.css'
import 'react-date-picker/dist/DatePicker.css'
import { Link, useNavigate } from 'react-router-dom'
import { BadgeStatusPrListInbounds } from '../../../components/atoms/StatusLabel'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import InputDatePicker from '../../../components/Input/DatePicker/InputDatePicker'
import api from '../../../config/AxiosInstance'
import { convertDateWithoutTime } from '../../../helpers/constants'
import * as Common from '../../../utils/Common'
const Transfer = () => {
  const headerTitleStyle = {
    color: 'var(--primary-orange)',
    fontSize: '24px',
    fontWeight: '600'
  }
  const titleStyle = {
    fontSize: '12px',
    letterSpacing: '0.5px'
  }
  const btnOrange = {
    backgroundColor: 'var(--primary-orange)',
    borderColor: 'var(--primary-orange)',
    color: 'white'
  }
  const navigate = useNavigate()
  const [transfers, setTransfers] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [warehouses, setWarehouses] = useState([])
  const [targetWarehouses, setTargetWarehouses] = useState([])
  const [filter, setFilter] = useState({
    tr: '',
    mr: '',
    src_warehouse: '',
    target_warehouse: '',
    start_date: '',
    end_date: ''
  })
  const [meta, setMeta] = useState({
    per_page: 10,
    current_page: 1,
    total_row: 0,
    total_page: 0
  })
  useEffect(() => {
    fetchWarehouses()
  }, [])
  useEffect(() => {
    fetchTransfers()
    fetchTargetWarehouses()
  }, [meta.per_page, meta.current_page])
  const fetchWarehouses = async () => {
    try {
      const res = await api.get('/master/warehouses/all?is_parent=true')
      const { data } = res
      if (data.success) {
        setWarehouses(data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchTargetWarehouses = async () => {
    try {
      const res = await api.get('/master/warehouses/all?is_transit=true')
      const { data } = res
      if (data.success) {
        setTargetWarehouses(data.data)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    }
  }
  const fetchTransfers = async () => {
    try {
      setIsLoading(true)
      const res = await api.get('/outbound/transfer/list', {
        params: { ...filter, per_page: meta.per_page, page: meta.current_page }
      })
      const { data } = res
      if (data.success) {
        setTransfers(data.data)
        setMeta(data.metadata)
      }
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setIsLoading(false)
    }
  }
  const handleChangeInput = (e) => {
    const { name, value } = e.target
    setFilter((prevFilter) => ({ ...prevFilter, [name]: value }))
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  return (
    <>
      <Container>
        <Row>
          <Col>
            <h1 style={headerTitleStyle}>TR LIST</h1>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col md={2}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="tr"
            >
              <small>#TR</small>
            </Form.Label>
            <Form.Control
              name="tr"
              id="tr"
              placeholder="Enter TR..."
              value={filter.tr}
              onChange={(e) => handleChangeInput(e)}
            />
          </Col>
          <Col md={2}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="mr"
            >
              <small>#MR</small>
            </Form.Label>
            <Form.Control
              name="mr"
              id="mr"
              placeholder="Enter MR..."
              value={filter.mr}
              onChange={(e) => handleChangeInput(e)}
            />
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="src_warehouse"
            >
              <small>source warehouse</small>
            </Form.Label>
            <Form.Select
              name="src_warehouse"
              id="src_warehouse"
              value={filter.src_warehouse}
              onChange={(e) => handleChangeInput(e)}
            >
              <option value="">All</option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.warehouse_id}>
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
          <Col md={4}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="target_warehouse"
            >
              <small>destination warehouse</small>
            </Form.Label>
            <Form.Select
              name="target_warehouse"
              id="target_warehouse"
              value={filter.target_warehouse}
              onChange={(e) => handleChangeInput(e)}
            >
              <option value="">All</option>
              {targetWarehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.warehouse_id}>
                  {warehouse.warehouse_name}
                </option>
              ))}
            </Form.Select>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="start_date"
            >
              <small>delivery date start</small>
            </Form.Label>
            <InputDatePicker
              value={filter?.start_date}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  start_date: moment(e).format('YYYY-MM-DD')
                })
              }
            />
          </Col>
          <Col md={2}>
            <Form.Label
              className="fw-semibold text-secondary text-uppercase"
              style={titleStyle}
              htmlFor="end_date"
            >
              <small>delivery date end</small>
            </Form.Label>
            <InputDatePicker
              value={filter?.end_date}
              onChange={(e) =>
                setFilter({
                  ...filter,
                  end_date: moment(e).format('YYYY-MM-DD')
                })
              }
            />
          </Col>
          <Col md={8} className="text-end mt-4 pt-2">
            <Button
              variant="transparent"
              className="btn me-2"
              onClick={() => {
                setFilter({
                  tr: '',
                  mr: '',
                  src_warehouse: '',
                  target_warehouse: '',
                  start_date: '',
                  end_date: ''
                })
              }}
            >
              Reset Filter
            </Button>
            <Button style={btnOrange} onClick={() => fetchTransfers()}>
              Apply Filter
            </Button>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={12}>
            <Table
              hover
              size="m"
              responsive="sm"
              className="shadow-sm mb-5 bg-white rounded"
              style={{ fontSize: '13px' }}
            >
              <thead className="table-secondary">
                <tr>
                  <th className="p-3 align-middle">#TR</th>
                  <th className="p-3 align-middle">#MR</th>
                  <th className="p-3 align-middle">Source Warehouse</th>
                  <th className="p-3 align-middle">Destination Warehouse</th>
                  <th className="p-3 align-middle">#Outbound</th>
                  <th className="p-3 align-middle">Status</th>
                  <th className="p-3 align-middle">Delivery Date</th>
                  <th className="p-3 align-middle">Action</th>
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td className="text-center" colSpan={8}>
                      <Loader />
                    </td>
                  </tr>
                )}
                {!isLoading && transfers.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={8}>
                      No data
                    </td>
                  </tr>
                )}
                {!isLoading &&
                  transfers.length > 0 &&
                  transfers.map((transfer) => (
                    <tr key={transfer.id}>
                      <td className="p-3">{transfer.tr}</td>
                      <td className="p-3">{transfer.mr}</td>
                      <td className="p-3">{transfer.src_warehouse}</td>
                      <td className="p-3">{transfer.target_warehouse}</td>
                      <td className="p-3">
                        {!transfer.outbound && '-'}
                        {transfer.outbound && (
                          <Link
                            to={`/outbound/plan/detail/${transfer?.outbound_id}`}
                            className="text-decoration-none"
                          >
                            {transfer.outbound}
                          </Link>
                        )}
                      </td>
                      <td className="p-3">
                        {<BadgeStatusPrListInbounds text={transfer.status} />}
                      </td>
                      <td className="p-3">
                        {convertDateWithoutTime(transfer.delivery_date)}
                      </td>
                      <td className="p-3 align-middle">
                        <TableAction>
                          <button
                            className="btn btn-secondary"
                            onClick={() =>
                              navigate(
                                `/outbound/transfer/detail/${transfer.id}`,
                                { replace: true }
                              )
                            }
                          >
                            <FontAwesomeIcon icon={faMagnifyingGlass} />
                          </button>
                        </TableAction>
                      </td>
                    </tr>
                  ))}
              </tbody>
              <tfoot>
                <tr>
                  <td className="p-3" colSpan={8}>
                    <TablePagination
                      meta={meta}
                      handleOnChangeMeta={handleOnChangeMeta}
                      handlePageChange={handlePageChange}
                    />
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Col>
        </Row>
      </Container>
    </>
  )
}

export default Transfer
