import { faPen } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { Col, Form, Row, Table } from 'react-bootstrap'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import Loader from '../../../components/atoms/Loader'
import TableAction from '../../../components/atoms/Table/TableAction'
import TablePagination from '../../../components/atoms/Table/TablePagination'
import ModalAddStagingArea from '../../../components/modal/stagingarea/ModalAddStagingArea'
import ModalUpdateStagingArea from '../../../components/modal/stagingarea/ModalUpdateStagingArea'
import api from '../../../config/AxiosInstance'
import '../../../styles/style.css'
import * as Common from '../../../utils/Common'

const headerTitleStyle = {
  color: 'var(--primary-orange)',
  fontSize: '24px',
  fontWeight: '600'
}

const initialMeta = {
  per_page: 10,
  current_page: 1,
  total_row: 0,
  total_page: 0
}

const MasterStaging = () => {
  const [showModalAdd, setShowModalAdd] = useState(false)
  const [showModalUpdate, setShowModalUpdate] = useState(false)
  const [disabledAdd, setDisabledAdd] = useState(true)
  const [loading, setLoading] = useState(false)
  const [stagings, setStagings] = useState([])
  const [isSelected, setIsSelected] = useState(false)
  const [warehouseId, setWarehouseId] = useState('')
  const [editId, setEditId] = useState('')
  const [meta, setMeta] = useState(initialMeta)
  const [options, setOptions] = useState([])
  const [loadingWarehouse, setLoadingWarehouse] = useState(false)
  const [inputWarehouse, setInputWarehouse] = useState('')
  const { per_page, current_page } = meta
  const filterBy = () => true

  const fetchStagings = async () => {
    setLoading(true)
    try {
      const params = new URLSearchParams({
        per_page,
        page: current_page
      })
      if (warehouseId) {
        params.append('warehouse_id', warehouseId)
      }

      const res = await api.get(`master/stagings?${params.toString()}`)
      const { metadata, data } = res.data
      setStagings(data)
      setMeta(metadata)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoading(false)
    }
  }

  const handleOnChangeMeta = (e) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      per_page: parseInt(e.target.value, 10),
      current_page: 1
    }))
  }

  const handlePageChange = (data) => {
    setMeta((prevMeta) => ({
      ...prevMeta,
      current_page: data.selected + 1
    }))
    window.scrollTo(0, 0)
  }

  const handleEdit = (id) => {
    setShowModalUpdate(true)
    setEditId(id)
  }

  useEffect(() => {
    fetchStagings()
  }, [per_page, current_page, isSelected])

  const handleSearch = async (query) => {
    setLoadingWarehouse(true)
    try {
      const params = new URLSearchParams({ page: 1, q: query })
      const res = await api.get(
        `master/warehouses/all?${params.toString()}&is_parent=true`
      )
      setOptions(res.data.data)
    } catch (err) {
      const errMsg = err?.response?.data?.errors?.message || 'An error occurred'
      Common.showPopUpErrorMessage(errMsg)
    } finally {
      setLoadingWarehouse(false)
    }
  }

  const handleGetDataSearch = () => {
    setMeta((prevMeta) => ({ ...prevMeta, current_page: 1 }))
    setIsSelected((prevIsSelected) => !prevIsSelected)
  }

  return (
    <div className="container">
      <h3 style={headerTitleStyle}>Staging Area</h3>
      <Row>
        <Col xs={9} md={11} lg={11}>
          <Form.Group>
            <Form.Label>Warehouse</Form.Label>
            <AsyncTypeahead
              id="basic-typeahead-single"
              labelKey="warehouse_name"
              loading={loadingWarehouse}
              filterBy={filterBy}
              onSearch={handleSearch}
              onChange={(selected) => {
                const selectedWarehouse =
                  selected.length > 0 ? selected[0] : null
                setInputWarehouse(
                  selectedWarehouse ? selectedWarehouse.warehouse_name : ''
                )
                setWarehouseId(selectedWarehouse ? selectedWarehouse.id : '')
                setDisabledAdd(!selectedWarehouse)
              }}
              options={options}
              placeholder="Choose a warehouse..."
            />
          </Form.Group>
        </Col>
        <Col xs={3} md={1} lg={1}>
          <div
            className="d-flex h-100 align-items-center justify-content-end"
            style={{ marginTop: '.9rem' }}
          >
            <button
              className="btn btn-primary-orange"
              onClick={() => handleGetDataSearch()}
              disabled={!inputWarehouse}
            >
              Select
            </button>
          </div>
        </Col>
      </Row>
      <div
        style={{
          borderBottom: '1px solid #F1F2F7',
          marginTop: '2.5rem'
        }}
      ></div>
      <div className="d-flex justify-content-between align-items-center py-3">
        <h4
          className="text-muted text-uppercase mt-2 mt-md-0 mt-lg-0"
          style={{ fontSize: '14px' }}
        >
          Staging Area List
        </h4>
        <button
          className="btn btn-primary-orange"
          disabled={disabledAdd}
          onClick={() => setShowModalAdd(true)}
        >
          Add
        </button>
      </div>
      <div className="row">
        <StagingTable
          stagings={stagings}
          loading={loading}
          onHandleEdit={handleEdit}
          meta={meta}
          handleOnChangeMeta={handleOnChangeMeta}
          handlePageChange={handlePageChange}
        />
      </div>
      <ModalAddStagingArea
        show={showModalAdd}
        onHide={() => setShowModalAdd(false)}
        data={warehouseId}
        refreshData={fetchStagings}
      />
      <ModalUpdateStagingArea
        show={showModalUpdate}
        onHide={() => {
          setShowModalUpdate(false)
        }}
        id={editId}
        refreshData={fetchStagings}
      />
    </div>
  )
}

const StagingTable = ({
  stagings,
  loading,
  onHandleEdit,
  meta,
  handleOnChangeMeta,
  handlePageChange
}) => {
  return (
    <Table
      responsive="sm"
      hover
      className="shadow-sm mb-5 bg-white rounded"
      size="sm"
      style={{ fontSize: '14px' }}
    >
      <thead className="table-secondary">
        <tr>
          <th className="p-2 align-middle">Name</th>
          <th className="p-2 align-middle text-end" style={{ width: '15%' }}>
            Total Block
          </th>
          <th className="p-2 align-middle text-end" style={{ width: '10%' }}>
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {loading ? (
          <tr>
            <td colSpan={4}>
              <Loader />
            </td>
          </tr>
        ) : stagings.length === 0 ? (
          <tr>
            <td colSpan={3} className="fw-bold text-center">
              No data
            </td>
          </tr>
        ) : (
          stagings?.map((staging, index) => (
            <tr key={index}>
              <td className="p-2 align-middle">{staging.name}</td>
              <td
                className="p-2 align-middle text-end"
                style={{ width: '15%' }}
              >
                {staging.block_total}
              </td>
              <td className="p-2 align-middle" style={{ width: '10%' }}>
                <TableAction>
                  <button
                    className="btn btn-secondary ml-2"
                    onClick={() => onHandleEdit(staging.id)}
                  >
                    <FontAwesomeIcon
                      icon={faPen}
                      style={{ fontSize: '15px', rotate: '90deg' }}
                      className="fa-flip-horizontal"
                    />
                  </button>
                </TableAction>
              </td>
            </tr>
          ))
        )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} className="p-3">
            <TablePagination
              meta={meta}
              handleOnChangeMeta={handleOnChangeMeta}
              handlePageChange={handlePageChange}
            />
          </td>
        </tr>
      </tfoot>
    </Table>
  )
}

export default MasterStaging
